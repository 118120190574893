<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    @click:outside="goBack"
  >
    <v-card>
      <v-card-title>
        {{ $t('projects.general.stories_movement') }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <ProjectAutocomplete
              v-model="selectedProjectId"
              :initial-item="selectedProject"
              :error-messages="projectUserStoryValidationErrors.project_id"
              :label="$t('projects.labels.choose_project')"
              @input="
                fetchSprints($event);
                clearErrors('project_id');
              "
            />
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="selectedSprintId"
              :error-messages="projectUserStoryValidationErrors.sprint_id"
              :items="sprints"
              :label="$t('projects.labels.choose_sprint')"
              :loading="loading['get:api/project-sprints']"
              item-text="sprint_name"
              item-value="id"
              clearable
              @change="clearErrors('sprint_id')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click.native="goBack">
          {{ $t('general.controls.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="loading[`post:api/project-user-stories/move`]"
          :loading="loading[`post:api/project-user-stories/move`]"
          @click="onSave"
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store';
import ProjectAutocomplete from '@/components/autocompletes/ProjectAutocomplete';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import projectSprintService from '@/api/project-sprint-service';

export default {
  name: 'MoveProjectUserStories',

  components: { ProjectAutocomplete },

  data() {
    return {
      selectedProjectId: null,
      selectedSprintId: null,
      sprints: [],
    };
  },

  computed: {
    ...mapState('projects', ['selectedProject']),
    ...mapState('projectSprints', ['projectSprints']),
    ...mapState('projectUserStories', ['projectUserStoryValidationErrors']),
    ...mapGetters(['loading']),
  },

  beforeRouteEnter(to, from, next) {
    const selectedUserStoryCount = Object.keys(
      store.state.projectUserStories.selectedProjectUserStoriesMap
    ).length;
    if (
      store.getters['projects/currentUserPermissionInSelectedProject'] !== 'admin' ||
      selectedUserStoryCount === 0
    ) {
      next({ name: 'project', params: { projectId: to.params.projectId } });
    } else {
      next();
    }
  },

  created() {
    this.SET_PROJECT_USER_STORY_VALIDATION_ERRORS({});
    this.selectedProjectId = this.selectedProject.id;
    this.sprints = this.projectSprints;
    this.selectedSprintId = this.projectSprints[0]?.id || null;
  },

  methods: {
    ...mapMutations('projectUserStories', [
      'SET_PROJECT_USER_STORY_VALIDATION_ERRORS',
      'CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS',
    ]),
    ...mapActions('projectUserStories', ['moveSelectedProjectUserStories']),

    clearErrors(key) {
      if (this.projectUserStoryValidationErrors[key]) {
        this.CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS(key);
      }
    },

    goBack() {
      this.$router.push({ name: 'project' });
    },

    onSave() {
      this.moveSelectedProjectUserStories({
        projectId: this.selectedProjectId,
        sprintId: this.selectedSprintId,
      }).then(() => {
        this.goBack();
      });
    },

    async fetchSprints(projectId) {
      if (projectId === this.selectedProject.id) {
        this.sprints = this.projectSprints;
        return;
      }

      const { data } = await projectSprintService.getAll({ project_id: projectId });
      this.sprints = data;
      this.selectedSprintId = this.sprints[0]?.id || null;
    },
  },
};
</script>

<style scoped></style>
